import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/Form"

const HometownHeroesReferral = () => {
  return (
    <Layout language="en">
      <SEO
        title="Hometown Heroes"
        description="Nominate a first responder, military personnel, healthcare worker, or other frontline hero for our Hometown Heroes program!"
      />
      <div className="hh-23">
        <div className="hh-23__hero">
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/Hometown_Heroes-Logo"
            alt="hometown heroes logo"
          />
          <h1>
            Do you have a staff member or patient who deserves a straighter
            smile?
          </h1>
        </div>

        <div className="hh-23__image-wall">
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-one"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-two"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-three"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-four"
            alt="people smiling"
          />
          <img
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/landing-page-hero-five"
            alt="people smiling"
          />
        </div>

        <div className="hh-23__section">
          <div className="hh-23__container">
            <div className="hh-23__content">
              <div>
                <p>
                  Our Hometown Heroes program honors the remarkable heroes in
                  our community by providing one recipient with free orthodontic
                  treatment (
                  <Link
                    to="/services/braces-fresno-ca/"
                    title="Learn more about braces">
                    braces
                  </Link>{" "}
                  or{" "}
                  <Link
                    to="/services/invisalign-fresno-clovis-ca/"
                    title="Learn more about Invisalign">
                    Invisalign<sup>Ⓡ</sup>
                  </Link>
                  ).
                </p>

                <p>
                  If you have a staff member or patient who should be recognized
                  for their good work in your practice or for helping others in
                  our community, we encourage you to nominate them. We are
                  accepting nominations now through February 9, 2023.
                </p>

                <p>
                  Stay tuned to this page,{" "}
                  <a
                    href="https://www.facebook.com/Nalchajian.Orthodontics"
                    title="Follow us on Facebook"
                    target="_blank">
                    Facebook
                  </a>
                  , and{" "}
                  <a
                    href="https://www.instagram.com/nalchajianorthodontics/"
                    title="Follow us on Instagram"
                    target="_blank">
                    Instagram
                  </a>{" "}
                  for program updates!
                </p>
              </div>
              <div className="bordered">
                <p className="bold hh-blue">The ideal nominee:</p>
                <ul>
                  <li>
                    Inspires others to help make our community a better place to
                    live
                  </li>
                  <li>
                    Has gone or goes above and beyond to aid a friend, family
                    member, or community member in a time of need
                  </li>
                  <li>
                    Has a love for their profession, and desire, determination,
                    and diligence to help outside of their normal job
                    responsibilities
                  </li>
                  <li>Lives and/or works in or near Fresno and/or Clovis</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="hh-23__form">
          <Form src="https://secureform.seamlessdocs.com/f/735f38a79115230ceb07fc098ea97a0b?embedded=true" />
        </div>
      </div>
    </Layout>
  )
}

export default HometownHeroesReferral
